body {
  font-family: "Inter", "system-ui";
}
.content-date {
  text-align: right;
}
.contact-title {
  text-align: left;
}
.contact {
  text-align: right;
}
/*# sourceMappingURL=index.20a57f06.css.map */

@charset 'utf-8';

@import "./main-dark.scss" (prefers-color-scheme: dark);
@import "./main-light.scss" (prefers-color-scheme: light);
@import 'npm:inter-ui/inter.css';


body {
  font-family: "Inter", "system-ui";
}

.content-date {
  text-align: right;
}

.contact-title {
  text-align: left;
}

.contact {
  text-align: right;
}
